import React from "react"
import * as S from "./styled"
import { withTrans } from "../../i18n/withTrans"
import OurImg from "../OurImg"
import Menu from "../Menu"

const OurSolutions = ({ t, i18n }) => (
  <>
    <S.Section>
      <S.SectionOne>
      <Menu/>
        <S.Wrapper>
          <div className="container">
            <div className="columns is-desktop">
              <div className="column is-flex is-justify-content-center is-flex-direction-column">
                <S.Title>{t("navbar.ourSolutions")}</S.Title>
                <S.Paragraph>
                  {t("ourSection.paragraphOne")}<br/><br/>{t("ourSection.paragraphTwo")}<br/><br/><span>{t("ourSection.paragraphTree")}</span>
                </S.Paragraph>
              </div>
              <div className="column is-flex is-justify-content-center is-align-items-center">
                <OurImg/>
              </div>
            </div>
          </div>
        </S.Wrapper>
      </S.SectionOne>
{/*       <div className="container mt-6">
        <div className="columns is-desktop">
          <div className="column mt-6">
            <S.WrapperCardOne>
              <S.TitleTwo>{t("ourSection.TitleTwo")}</S.TitleTwo>
              <div className="container mb-6">
                <ForEmployers/>
              </div>
            </S.WrapperCardOne>
            <S.WrapperCardTwo>
              <S.TitleTwo>{t("ourSection.TitleTree")}</S.TitleTwo>
              <div className="container">
                <ForEmployees/>
              </div>
            </S.WrapperCardTwo>
          </div>
        </div>
      </div> */}
    </S.Section>
  </>
)

export default withTrans(OurSolutions)