import React from "react"
import * as S from "./styled"
import { withTrans } from "../../i18n/withTrans"

const BasicFeatures = ({ t, i18n }) => (
    <>
        <div className="container mt-6">
            <div className="columns is-desktop">
                <div className="column">
                    <S.TitleTwo>{t("basicFeatures.titleOne")}</S.TitleTwo>
                </div>
            </div>
        </div>
        <S.Section>
            <div className="container">
                <S.WrapperCard>
                    <S.Card color="#7AD1A6">
                    <S.Title>{t("basicFeatures.titleCardOne")}</S.Title>
                    <S.Description>{t("basicFeatures.contentCardOne")}</S.Description>
                    <br/>
                    <S.Description>{t("basicFeatures.bulletCardOne")}</S.Description>
                    <S.Description>{t("basicFeatures.bulletCardOneTwo")}</S.Description>
                    <S.Description>{t("basicFeatures.bulletCardOneThree")}</S.Description>
                    <S.Description>{t("basicFeatures.bulletCardOneFour")}</S.Description>
                    </S.Card>
                </S.WrapperCard>
            </div>
            <div className="container">
                <S.WrapperCard>
                    <S.Card color="#AA78ED">
                    <S.Title>{t("basicFeatures.titleCardTwo")}</S.Title>
                    <S.Description>{t("basicFeatures.contentCardTwo")}</S.Description>
                    <br/>
                    <S.Description>{t("basicFeatures.bulletCardTwo")}</S.Description>
                    <S.Description>{t("basicFeatures.bulletCardTwoTwo")}</S.Description>
                    <S.Description>{t("basicFeatures.bulletCardTwoThree")}</S.Description>
                    <S.Description>{t("basicFeatures.bulletCardTwoFour")}</S.Description>
                    <br/>
                    <S.Description>{t("basicFeatures.bulletCardTwoFive")}</S.Description>
                    </S.Card>
                </S.WrapperCard>
            </div>
            <div className="container">
                <S.WrapperCard>
                    <S.Card color="#ED7878">
                    <S.Title>{t("basicFeatures.titleCardThree")}</S.Title>
                    <S.Description>{t("basicFeatures.contentCardTthree")}</S.Description>
                    <br/>
                    <S.Description>{t("basicFeatures.bulletCardThreeOne")}</S.Description>
                    <S.Description>{t("basicFeatures.bulletCardThreeTwo")}</S.Description>
                    <S.Description>{t("basicFeatures.bulletCardThreeThree")}</S.Description>
                    </S.Card>
                </S.WrapperCard>
            </div>
            <div className="container">
                <S.WrapperCard>
                    <S.Card color="#EDED78">
                    <S.Title>{t("basicFeatures.titleCardFour")}</S.Title>
                    <S.Description>{t("basicFeatures.contentCardFour")}</S.Description>
                    <br/>
                    <S.Description>{t("basicFeatures.bulletCardFourOne")}</S.Description>
                    <S.Description>{t("basicFeatures.bulletCardFourTwo")}</S.Description>
                    <S.Description>{t("basicFeatures.bulletCardFourThree")}</S.Description>
                    </S.Card>
                </S.WrapperCard>
            </div>
        </S.Section>

        {/*  Section Two */}
        <div className="container">
            <div className="columns is-desktop mt-6">
                <div className="column mt-6">
                    <S.TitleTwo>{t("basicFeatures.titleTwo")}</S.TitleTwo>
                </div>
            </div>
        </div>

        <S.Section>
            <div className="container">
                <S.WrapperCard>
                    <S.Card color="#ED78D7">
                    <S.Title>{t("basicFeatures.titleCardOneSectionTwo")}</S.Title>
                    <S.Description>{t("basicFeatures.contentCardOneSectionTwo")}</S.Description>
                    <br/>
                    <S.Description>{t("basicFeatures.bulletCardOneSectionTwo")}</S.Description>
                    <S.Description>{t("basicFeatures.bulletCardTwoSectionTwo")}</S.Description>
                    <S.Description>{t("basicFeatures.bulletCardThreeSectionTwo")}</S.Description>
                    <S.Description>{t("basicFeatures.bulletCardFourSectionTwo")}</S.Description>
                    <S.Description>{t("basicFeatures.bulletCardFiveSectionTwo")}</S.Description>
                    </S.Card>
                </S.WrapperCard>
            </div>
        </S.Section>

        {/*  Section Three */}
        <S.WrapperThree mobilitle>
            <div className="container">
                <div className="columns is-desktop mt-6">
                    <div className="column mt-6">
                        <S.TitleTwo>{t("basicFeatures.titleThree")}</S.TitleTwo>
                    </div>
                </div>
            </div>

            <S.Section>
            <div className="container">
                <S.WrapperCard>
                    <S.Card color="#F8A11E">
                    <S.Title>{t("basicFeatures.titleCardOneSectionThree")}</S.Title>
                    <S.Description>{t("basicFeatures.contentCardOneSectionThree")}</S.Description>
                    <br/>
                    <S.Description>{t("basicFeatures.bulletCardOneSectionThree")}</S.Description>
                    <S.Description>{t("basicFeatures.bulletCardOneSectionTtwo")}</S.Description>
                    </S.Card>
                </S.WrapperCard>
            </div>
            <div className="container">
                <S.WrapperCard>
                    <S.Card color="#A5D15C">
                    <S.Title>{t("basicFeatures.titleCardtwoSectionThree")}</S.Title>
                    <S.Description>{t("basicFeatures.contentCardTwoSectionThree")}</S.Description>
                    <br/>
                    <S.Description>{t("basicFeatures.bulletCardOneTwoSectionThree")}</S.Description>
                    <S.Description>{t("basicFeatures.bulletCardTwoTwoSectionTtwo")}</S.Description>
                    <S.Description>{t("basicFeatures.bulletCardThreeTwoSectionTtwo")}</S.Description>
                    </S.Card>
                </S.WrapperCard>
            </div>
            <div className="container">
                <S.WrapperCard>
                    <S.Card color="#2481C5">
                    <S.Title>{t("basicFeatures.titleCardthreeSectionThree")}</S.Title>
                    <S.Description>{t("basicFeatures.contentCardThreeSectionThree")}</S.Description>
                    <br/>
                    <S.Description>{t("basicFeatures.bulletCardOneThreeSectionThree")}</S.Description>
                    <S.Description>{t("basicFeatures.bulletCardTwoThreeSectionTtwo")}</S.Description>
                    </S.Card>
                </S.WrapperCard>
            </div>
            </S.Section>
        </S.WrapperThree>

        <S.WrapperThree>
            {/*  Section Four */}
            <div className="container mt-6">
                <div className="columns is-desktop">
                    <div className="column">
                        <S.TitleTwo>{t("basicFeatures.titleFour")}</S.TitleTwo>
                    </div>
                </div>
            </div>
            <S.Section>
            <div className="container">
                <S.WrapperCard>
                    <S.Card color="#D15C5C">
                    <S.Title>{t("basicFeatures.titleCardFourSectionThree")}</S.Title>
                    <S.Description>{t("basicFeatures.contentCardFourSectionThree")}</S.Description>
                    <br/>
                    <S.Description>{t("basicFeatures.bulletCardOneFourSectionThree")}</S.Description>
                    <S.Description>{t("basicFeatures.bulletCardTwoFourSectionTtwo")}</S.Description>
                    </S.Card>
                </S.WrapperCard>
            </div>
            </S.Section>
        </S.WrapperThree>


        <S.WrapperThree>
            {/*  Section Five */}
                <div className="container mt-6">
                <div className="columns is-desktop mt-6">
                    <div className="column">
                        <S.TitleTwo>{t("basicFeatures.titleFive")}</S.TitleTwo>
                    </div>
                </div>
                </div>
            <S.Section>

            <div className="container">
                <S.WrapperCard>
                    <S.Card color="#2F8B0E">
                    <S.Title>{t("basicFeatures.titleCardOneSectionFour")}</S.Title>
                    <S.Description>{t("basicFeatures.contentCardOneSectionFour")}</S.Description>
                    </S.Card>
                </S.WrapperCard>
            </div>
            <div className="container">
                <S.WrapperCard>
                    <S.Card color="#DB2AD4">
                    <S.Title>{t("basicFeatures.titleCardTwoSectionFour")}</S.Title>
                    <S.Description>{t("basicFeatures.contentCardTwoSectionFour")}</S.Description>
                    <br/>
                    <S.Description>{t("basicFeatures.bulletCardOneSectionFour")}</S.Description>
                    <S.Description>{t("basicFeatures.bulletCardTwoSectionFour")}</S.Description>
                    </S.Card>
                </S.WrapperCard>
            </div>
            <div className="container">
                <S.WrapperCard>
                    <S.Card color="#E3E72A">
                    <S.Title>{t("basicFeatures.titleCardThreeSectionFour")}</S.Title>
                    <S.Description>{t("basicFeatures.contentCardThreewoSectionFour")}</S.Description>
                    </S.Card>
                </S.WrapperCard>
            </div>
            </S.Section>
            
            </S.WrapperThree>


        <S.WrapperThree>
            {/*  Section Six */}
            <div className="container">
                <div className="columns is-desktop mt-6">
                    <div className="column mt-6">
                        <S.TitleTwo>{t("basicFeatures.titleSix")}</S.TitleTwo>
                    </div>
                </div>
            </div>
            <S.Section>
            <div className="container">
                <S.WrapperCard>
                    <S.Card color="#5A84F0">
                    <S.Title>{t("basicFeatures.titleCardFourSectionFour")}</S.Title>
                    <S.Description>{t("basicFeatures.contentCardFourwoSectionFour")}</S.Description>
                    </S.Card>
                </S.WrapperCard>
            </div>
            <div className="container">
                <S.WrapperCard>
                    <S.Card color="#FF5487">
                    <S.Title>{t("basicFeatures.titleCardFiveSectionFour")}</S.Title>
                    <S.Description>{t("basicFeatures.contentCardFivewoSectionFour")}</S.Description>
                    </S.Card>
                </S.WrapperCard>
            </div>
            </S.Section>
        </S.WrapperThree>
    </>
)

export default withTrans(BasicFeatures)
