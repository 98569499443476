import React from "react"
import Layout from "../components/Layout"
import Menu from "../components/Menu"
import OurSolutionsContent from "../components/OurSolutions"
import SEO from "../components/seo"
import { ThemeProvider } from "styled-components"
import General from "../styles/theme"
import Solutions from "../components/Solutions"
import BasicFeatures from "../components/BasicFeatures"

const OurSolutionsPage = () => (
  <ThemeProvider theme={General}>
    <Layout>
      <SEO title="Our Solutions" />
      {/* <Menu/> */}
      <OurSolutionsContent/>
      {/* <Solutions/> */}
      <BasicFeatures/>
    </Layout>
  </ThemeProvider>
)

export default OurSolutionsPage