import styled from "styled-components"
import media from "styled-media-query"

export const Section = styled.section`
    margin-top: 100px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 400px;
    grid-gap: 50px;
    margin: 0 100px;
    ${media.lessThan("small")`
      margin-top: 10px;
      margin: 0;
    `}
` 
export const TitleTwo = styled.h2`
  font-size: 40px;
  font-family: "Josefin Sans", sans-serif;
  color: ${props => props.theme.colors.subtitle};
  font-weight: 600;
  line-height: 20px;
  text-align: center;
    ${media.between("medium", "large")`;
      font-size: 35px;
    `}
    ${media.lessThan("small")`
      font-size: 25px;
      margin: 40px 0;
    `}
`

//////////////////// Cards ////////////////////

export const WrapperCard = styled.div`
    margin-top: 80px;
    ${media.between("medium", "large")`;
      margin: 50px 50px;
    `}
    ${media.lessThan("small")`
      margin: 30px 20px;
      width: 360px;
      height: auto;
    `}
    
`

export const Card = styled.div`
  width: 100%;
  min-height: 400px;
  box-shadow: 0px 20px 20px #7682B72E;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 10px 30px 30px 30px;
  border-left: solid 6px ${props => props.color || "palevioletred"};
  ${media.lessThan("small")`
    height: auto;
    margin-bottom: 30px;
  `}
`

export const Description = styled.p`
  font-size: 20px;
  font-family: "Josefin Sans", sans-serif;
  color: #4A4A4A;
  line-height: 30px;
  padding-right: 20px;
  font-weight: 300;
  ${media.lessThan("small")`
    //padding-bottom: 40px;
    font-size: 16px;
  `}
  ${media.between("medium", "large")`
    font-size: 16px;
  `}
`

export const Title = styled.h4`
  font-size: 25px;
  font-family: "Josefin Sans", sans-serif;
  line-height: 70px;
  color: #4A4A4A;
  font-weight: 600;
  ${media.lessThan("small")`
    line-height: 40px;
    font-size: 20px;
  `}
`

export const WrapperThree = styled.div`
  margin-top: 150px;
  ${media.lessThan("small")`
    margin-top: 0;
    margin-top: ${props => props.mobilitle ? '180px' : '0'};
  `}
`


